<template>
  <div class="card shadow mb-4 bg-white rounded">
    <div class="card-body cd1">
      <form ref="form" class="row row-cols-lg-2 g-2 align-items-center" @submit.prevent="submit">
        <div class="col-12">
          <!-- <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">統計項目</small></label> -->
          <!-- <div class="input-group"> -->
          <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
            <label class="form-check-label" for="flexRadioDefault1"> 逐月平均票價 </label>
          </div>
          <!-- </div> -->
        </div>
        <div class="col-12">
          <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">統計月份</small></label>
          <div class="input-group">
            <div class="input-group-text">
              <i class="bi bi-calendar-range"></i>
            </div>
            <template v-if="!flag">
              <select class="form-control form-select input-height" id="select1" v-model="form.selectedItems1" required>
                <option value="" disabled>=== 25夏 ===</option>
                <option value="2025-01|S25" disabled>2025-01 - 25夏 (2025/03/30 ~ 2025/10/25)</option>
                <option value="2024-12|S25">2024-12 - 25夏 (2025/03/30 ~ 2025/10/25)</option>
                <option value="" disabled>=== 24冬 ===</option>
                <option value="2025-01|W24" disabled>2025-01 - 24冬 (2024/10/27 ~ 2025/03/29)</option>
                <option value="2024-12|W24">2024-12 - 24冬 (2024/10/27 ~ 2025/03/29)</option>
              </select>
            </template>
            <template v-else>
              <select class="form-control form-select input-height" id="select1" v-model="form.selectedItems1" required>
                <option value="" disabled>=== 25夏 ===</option>
                <option value="2025-01|S25" disabled>2025-01 - 25夏 (2025/03/30 ~ 2025/10/25)</option>
                <option value="2024-12|S25">2024-12 - 25夏 (2025/03/30 ~ 2025/10/25)</option>
                <option value="2024-11|S25">2024-11 - 25夏</option>
                <option value="" disabled>=== 24冬 ===</option>
                <option value="2025-01|W24" disabled>2025-01 - 24冬 (2024/10/27 ~ 2025/03/29)</option>
                <option value="2024-12|W24">2024-12 - 24冬 (2024/10/27 ~ 2025/03/29)</option>
                <option value="2024-11|W24">2024-11 - 24冬</option>
                <option value="2024-10|W24">2024-10 - 24冬</option>
                <option value="2024-09|W24">2024-09 - 24冬</option>
                <option value="2024-08|W24">2024-08 - 24冬</option>
                <option value="2024-07|W24">2024-07 - 24冬</option>
                <option value="2024-06|W24">2024-06 - 24冬</option>
                <option value="2024-05|W24">2024-05 - 24冬</option>
                <option value="2024-04|W24">2024-04 - 24冬</option>
                <option value="" disabled>=== 24夏 ===</option>
                <option value="2024-10|S24">2024-10 - 24夏 (2024/03/31 ~ 2024/10/26)</option>
                <option value="2024-09|S24">2024-09 - 24夏</option>
                <option value="2024-08|S24">2024-08 - 24夏</option>
                <option value="2024-07|S24">2024-07 - 24夏</option>
                <option value="2024-06|S24">2024-06 - 24夏</option>
                <option value="2024-05|S24">2024-05 - 24夏</option>
                <option value="2024-04|S24">2024-04 - 24夏</option>
                <option value="2024-03|S24">2024-03 - 24夏</option>
                <option value="2024-02|S24">2024-02 - 24夏</option>
                <option value="2024-01|S24">2024-01 - 24夏</option>
                <option value="2023-12|S24">2023-12 - 24夏</option>
                <option value="2023-11|S24">2023-11 - 24夏</option>
                <option value="" disabled>=== 23冬 ===</option>
                <option value="2024-02|W23">2024-02 - 23冬 (2023/10/29 ~ 2024/03/30)</option>
                <option value="2024-01|W23">2024-01 - 23冬</option>
                <option value="2023-12|W23">2023-12 - 23冬</option>
                <option value="2023-11|W23">2023-11 - 23冬</option>
                <!-- <option value="RT">來回</option> -->
              </select>
            </template>
          </div>
        </div>
        <div class="col-12">
          <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">出發地</small></label>
          <div class="input-group">
            <div class="input-group-text">
              <font-awesome-icon icon="plane-departure" />
            </div>

            <select class="form-control form-select input-height" name="" v-model="countryName" @change="selectCity" required>
              <option :value="item" v-for="(item, index) in area4stat" :key="index" :selected="selectedDay === item.country">
                {{ item.country }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12">
          <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">目的地</small></label>
          <div class="input-group">
            <div class="input-group-text">
              <font-awesome-icon icon="plane-arrival" />
            </div>
            <select class="form-control form-select input-height" name="" v-model="cityName" @change="setCountryCity" required>
              <option :value="item" v-for="(item, index) in countryName.city" :key="index">
                {{ item }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12"></div>
        <div class="col-12">
          <button class="btn btn-danger btn-lg float-end mt-4" type="submit">查統計</button>
        </div>
      </form>
    </div>
  </div>
</template>
<style>
  .input-height {
    height: 39px;
  }
  .cd1 {
    background-image: linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), url("../assets/1971537.jpg");
    border-radius: 50px;
  }
</style>

<script>
  // import Cookies from "js-cookie"
  import iataCode from "@/mixins/iataCode.js"
  import GlobalVar from "@/mixins/global_var.js"
  export default {
    name: "VueStatView",
    mixins: [iataCode, GlobalVar],
    data() {
      return {
        countryName: {},
        cityName: "請選擇",
        selectedDay: "台北(桃園)|TPE",
        // city: [],
        // inputContent: 'rrr',
        form: {
          countryName1: [],
          cityName1: [],
          selectedItems1: "2024-12|W24",
          // selectedItems2: [],
          // selectedItems3: [],
          // dateRange: {},
          // dateRange2: {},
        },
        flag: true,
      }
    },
    mounted() {
      // if (Cookies.get("login_token")) {
      // const login_token = Cookies.get("login_token")
      // this.checkLoginTokeVaild(login_token)

      // if (!sessionStorage.getItem("is_email_confirm")) {
      //   this.$alert("10/1 起本功能僅提供通過帳號驗證之會員使用，如尚未完成者僅能查詢最新一筆統計資料", "系統訊息", "error")
      //   this.flag = false
      // }

      if (sessionStorage.getItem("role_type") !== "Sponsored") {
        this.$alert("2024/11/1 起若非付費會員，僅能查詢夏冬季最近各一筆統計資料", "系統訊息", "error")
        this.flag = false
      }
      // }
    },
    methods: {
      // getD(val) {
      //   this.form.dateRange = val
      // },
      // getD2(val) {
      //   this.form.dateRange2 = val
      // },
      submit() {
        const formData = this.form
        this.$emit("query", formData)
      },
      selectCity(value) {
        const selectedIndex = value.target.options.selectedIndex
        this.form.countryName1 = this.area4stat[selectedIndex].country
      },
      setCountryCity(value) {
        this.form.cityName1 = ""
        const selectedIndex = value.target.options.selectedIndex
        const countryName = this.form.countryName1
        const area = this.area4stat
        area.forEach((e) => {
          if (e.country === countryName) {
            this.form.cityName1 = e.city[selectedIndex]
          }
        })
      },
    },
  }
</script>

<style></style>
