<template>
  <div class="col m-6">
    <div class="container-fluid">
      <div class="row row-cols-1 row-cols-md-12 g-4">
        <div class="col mt-5">
          <div class="alert alert-danger mt-4" role="alert">網站服務調整 (2025/3/30起) <a href="https://www.facebook.com/just.bk.me/posts/967596972205081" target="_blank">見詳細公告</a></div>
        </div>
        <h4>
          <!-- <p class="mt-5">台灣虎航 24夏季第一波開賣各航點價格一覽表<small> (逐步更新，非完整)</small></p> -->
          <p class="mt-0">{{ main_title }}<small> (不定時更新)</small></p>
        </h4>
        <small class="mt-0">
          <!-- <h2><p>台灣虎航 24夏季第一波開賣各航點價格一覽表</p></h2> -->
          <ul>
            <!-- <li>若登入後仍查無資料，代表本站今日尚未進行資料更新，<span class="fs-4 text-danger">票價資料更新原則：不定期、出發日近的先</span></li> -->
            <li>溫馨提醒：如透過行動裝置進行操作，由於本站對於in-app瀏覽器（如Facebook 或 LINE 內建）支援程度不佳，如遇到資料無法呈現，可建議使用 Google Chrome、Safari 等系統原生或第三方瀏覽器</li>
            <!-- <li v-if="help_title !== ''">
              <span class="fs-6 text-danger">{{ help_title }}</span
              >，若沒有可以點擊一旁 <router-link to="/NewUser" class="btn btn-outline-primary"> 註冊 </router-link>
            </li> -->
            <li>若使用行動裝置，建議可切換成橫向檢視來得到較佳瀏覽體驗</li>
            <li>所提供的票價資訊均是建立在「直飛，日本出發的 <span class="fs-4 text-danger"> 單程 </span>且大人人數是 1 位」的情況</li>
            <li>僅供參考，由於資料本身具有時效性，建議要搭配參考資料取得時間 (UTC+8)</li>
            <li v-html="sub_title"></li>
          </ul>
        </small>
        <template>
          <div class="modal" tabindex="-1" id="myModal2">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">特殊規定及使用說明 (每次開啟都會跳出，暫時會維持一段時間)</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <p class="fs-4 text-danger">目前OTA平台暫時查不到台灣虎航的機票資訊</p>
                  <p class="fs-4 text-danger">由於這情況有一陣子了，是否該 OTA不再販售，不排除有這個可能，目前還會再多觀察一陣子</p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                </div>
              </div>
            </div>
          </div>
        </template>
        <template>
          <div class="modal" tabindex="-1" id="myModal">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">特殊規定及使用說明 (每次開啟都會跳出，暫時會維持一段時間)</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <p class="fs-4 text-danger">真心還是希望能低調，畢竟你我也不想看到被大肆公開後被改掉的情況</p>
                  <p class="fs-4 text-danger">只想買促銷價的建議可以跳過這個，這並不是給你買促銷價用的</p>
                  <ul>
                    <li>更新原則：不定期、出發日比較近的先，另外正常搜尋會查不到也會先更新</li>
                    <!-- <li>目前為 邀請制，需要使用 Just.崩潰 會員帳號登入驗身份</li> -->
                    <li><span class="text-danger">不會和其他懶人包共同列入每月額度計算</span></li>
                    <li><span class="text-danger">若登入後仍查無資料，代表今日尚未進行資料更新</span></li>
                  </ul>
                  <hr />
                  <ul>
                    <li>因為是跟 OTA 買，有問題要找 OTA 而非航空公司（也請不要問我怎麼辦，自己要有能力解決）</li>
                    <li>除非 OTA 有特別說明票種規定，不然原則上你只能根據航空公司訂位系統上面目前同一天同一班次的艙等去推測，所以 <span class="fs-4 text-danger">購票前請務必要了解當下購票的票種使用規定</span></li>
                    <li>並不是所有日期、所有航線都查的到</li>
                    <li><span class="fs-4 text-danger">一定要跟航空公司官網比價過</span></li>
                  </ul>
                  <hr />
                  <p>另外對於台灣虎航部份(日本線)</p>
                  <ul>
                    <li>正常搜尋查不到的：札幌＝高雄（若你會修改網址參數可以自行修改網址參數）</li>
                    <li>24冬季</li>
                    <ul>
                      <li>唯二查不到的：宮崎＝台北、福島＝台北</li>
                      <!-- <li>正常搜尋查不到的：札幌＝高雄（若你會修改網址參數可以自行修改網址參數）</li> -->
                    </ul>
                    <li>25夏季</li>
                    <ul>
                      <li>目前可能查不到的：大分＝台北、米子＝台北</li>
                      <!-- <li>除上述所列航線外，其他都有同步開放，沒開放的後續有興趣可自行關注一下開放情況</li> -->
                      <!-- <li>正常搜尋查不到的：札幌＝高雄（若你會修改網址參數可以自行修改網址參數）</li> -->
                      <li>其餘航線除上述所提加上官方還沒賣的外，"理論上"應該都查得到</li>
                    </ul>
                  </ul>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                </div>
              </div>
            </div>
          </div>
        </template>
        <template>
          <div class="ad-container">
            <Adsense data-ad-client="ca-pub-2965351057490665" data-ad-slot="5295990485" data-ad-format="auto" data-full-width-responsive="true"> </Adsense>
          </div>
        </template>
        <vue-good-table
          :line-numbers="false"
          :search-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
          }"
          :sort-options="{
            enabled: true,
            multipleColumns: true,
            initialSortBy: [
              { field: 'departureDate', type: 'asc' },
              { field: 'fareAmount', type: 'asc' },
            ],
          }"
          :columns="columns"
          :rows="rows"
          styleClass="vgt-table striped" />
      </div>
    </div>
  </div>
  <!-- <script type="text/javascript">
    $(window).on("load", function () {
      $("#myModal").modal("show")
    })
  </script> -->
</template>

<script>
  import { Modal } from "bootstrap"
  import axios from "axios"
  import Cookies from "js-cookie"
  import GlobalVar from "@/mixins/global_var.js"
  export default {
    name: "VueLowFare",
    // components: { Table },
    mounted() {
      // if (Cookies.get("login_token")) {
      const login_token = Cookies.get("login_token")
      this.checkLoginTokeVaild(login_token)

      let redirect_url = "/Login"

      if (this.$route.query.ref) {
        const page = this.$route.query.ref
        // const airline = ref.split("-")[0]
        // const tag = ref.split("-")[1]
        redirect_url = `/Login?url=/LowFareOTA?ref=${page}`
      }

      // this.flag = sessionStorage.getItem("ref")
      // if (!sessionStorage.getItem("is_email_confirm")) {
      // if (sessionStorage.getItem("is_email_confirm")) {
      // this.columns.splice(4, 0, {
      //   label: "張數",
      //   width: "100px",
      //   field: "fareAvailable",
      //   filterOptions: {
      //     styleClass: "class1", // class to be added to the parent th element
      //     enabled: true, // enable filter for this column
      //     placeholder: "", // placeholder for filter input
      //     filterValue: "", // initial populated value for this filter
      //     // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
      //     filterFn: this.columnFilterFn, //custom filter function that
      //     // trigger: "enter", //only trigger on enter not on keyup
      //   },
      // })

      this.columns.splice(1, 0, {
        label: "航空業者",
        width: "100px",
        field: "airline",
        filterOptions: {
          styleClass: "class1", // class to be added to the parent th element
          enabled: true, // enable filter for this column
          placeholder: "", // placeholder for filter input
          filterValue: "IT", // initial populated value for this filter
          filterDropdownItems: ["MM", "SL", "VZ", "TR", "D7", "FD", "AK", "IT", "CI", "BR", "JX", "JL", "NH", "UA", "OD"], // dropdown (with selected values) instead of text input
          // filterFn: this.columnFilterFn, //custom filter function that
          // trigger: "enter", //only trigger on enter not on keyup
        },
      })
      // }
      // }
      // }
      let airline = null,
        tag = null

      this.main_title = "OTA 價格整理"
      this.sub_title = '價格單位: 視各業者票種而定，幣值為 JPY 且 <span class="fs-5 text-danger">單程含稅(也包含其他費用)</span> '

      if (sessionStorage) {
        sessionStorage.setItem("page", "LowFareOTA")
        sessionStorage.setItem("ref", false)
        sessionStorage.removeItem("airline", airline)
        sessionStorage.removeItem("tag", tag)
      }

      if (Cookies) {
        Cookies.set("page", "LowFareOTA", { secure: true })
        Cookies.set("ref", false, { secure: true })
        Cookies.remove("airline")
        Cookies.remove("tag")
      }

      if (this.$route.query.ref) {
        const ref = this.$route.query.ref
        airline = ref.split("-")[0]
        tag = ref.split("-")[1]

        if (airline === "TEST" && tag === "TT") {
          this.main_title = "測試用"
          this.sub_title = ""

          if (typeof sessionStorage !== "undefined") {
            alert("ok")
          } else {
            alert("not ok")
          }
        }
        this.help_title = "如持有本站會員帳號，可於登入後查看該優惠價格所持有的張數"
        if (sessionStorage) {
          sessionStorage.setItem("ref", true)
          sessionStorage.setItem("airline", airline)
          sessionStorage.setItem("tag", tag)
        }
        if (Cookies) {
          Cookies.set("ref", true, { secure: true })
          Cookies.set("airline", airline, { secure: true })
          Cookies.set("tag", tag, { secure: true })
        }
      }

      if (!sessionStorage.getItem("is_email_confirm")) {
        this.$alert("本頁面為邀請制，須持有本站會員帳號且為受邀請者才能觀看", "系統訊息", "error").then(() => {
          window.location.href = `${redirect_url}`
        })
      } else {
        this.initLowFare()
      }
    },
    mixins: [GlobalVar],
    data: function () {
      return {
        flag: null,
        main_title: "",
        sub_title: "",
        help_title: "",
        isLoading: false,
        columns: [
          {
            label: "前往官網",
            width: "150px",
            field: "goLink",
            html: true,
          },
          {
            label: "出發日",
            width: "150px",
            field: "departureDate",
            type: "date",
            dateInputFormat: "yyyy-MM-dd",
            dateOutputFormat: "yyyy-MM-dd",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "星期",
            field: "departureDateWeeKFormat",
            width: "130px",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "班次",
            field: "flightNo",
            width: "130px",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },
          // {
          //   label: "currency",
          //   field: "currency",
          // },
          {
            label: "價格",
            width: "130px",
            field: "fareAmount",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },

          {
            label: "出發地",
            width: "150px",
            field: "departureStation",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "目的地",
            width: "150px",
            field: "arrivalStation",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "資料時間",
            width: "150px",
            field: "fetchCreatedAt",
          },
        ],
        rows: [],
      }
    },
    methods: {
      showModal() {
        // if (this.flag) {
        const myModal = new Modal(document.getElementById("myModal2"))
        myModal.show()
        // }
      },
      initLowFare: async function () {
        const me = this

        let user_uuid = null
        if (sessionStorage) {
          if (sessionStorage.getItem("uuid")) {
            user_uuid = sessionStorage.getItem("uuid")
          }
        }

        if (Cookies) {
          if (Cookies.get("uuid")) {
            user_uuid = Cookies.get("uuid")
          }
        }

        const page = this.$route.query.ref

        const json_d = {
          uuid: user_uuid,
          ref: page,
        }

        await axios({
          method: "POST",
          url: `${me.$apiUrl2}checkPageWhiteList`,
          data: json_d,
          headers: {
            Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
            "Content-Type": "application/json",
          },
        }).then(async function (response) {
          let flag = null
          flag = response.data

          if (flag) {
            // axios.get(`${me.$apiUrl}lowfareByOTA`).then((response) => {
            //   me.rows = response.data
            // })
            me.showModal()
            axios({
              method: "POST",
              url: `${me.$apiUrl}lowfareByOTA`,
              data: json_d,
              headers: {
                Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
                "Content-Type": "application/json",
              },
            }).then(function (response) {
              me.rows = response.data
            })
          } else {
            me.$alert("非邀請對象，將引導回首頁", "系統訊息", "error").then(() => {
              window.location.href = `/`
            })
          }
        })

        // if (airline == null && tag == null) {
        //   // let usage = null

        // } else {
        //   let user_uuid = null

        //   if (sessionStorage) {
        //     if (sessionStorage.getItem("uuid")) {
        //       user_uuid = sessionStorage.getItem("uuid")
        //     }
        //   }

        //   if (Cookies) {
        //     if (Cookies.get("uuid")) {
        //       user_uuid = Cookies.get("uuid")
        //     }
        //   }

        //   const json_d = {
        //     airline: airline,
        //     tag: tag,
        //     uuid: user_uuid,
        //   }

        //   let usage = null

        //   axios({
        //     method: "POST",
        //     url: `${me.$apiUrl}querylowfare_usage2`,
        //     data: json_d,
        //     headers: {
        //       Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
        //       "Content-Type": "application/json",
        //     },
        //   }).then(function (response) {
        //     usage = response.data[0]
        //     // console.log(usage)
        //     let flag = false
        //     if (sessionStorage.getItem("role_type") === "Sponsored") {
        //       flag = true
        //     } else {
        //       if (usage.count <= 5 && usage.count >= 0) {
        //         flag = true
        //       } else {
        //         flag = false
        //       }
        //     }

        //     if (flag) {
        //       axios({
        //         method: "POST",
        //         url: `${me.$apiUrl}lowfareByOTA`,
        //         data: json_d,
        //         headers: {
        //           Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
        //           "Content-Type": "application/json",
        //         },
        //       }).then(function (response) {
        //         me.rows = response.data
        //       })
        //     } else {
        //       me.$alert("非邀請對象，將引導回首頁", "系統訊息", "error").then(() => {
        //         window.location.href = `/`
        //       })
        //     }
        //   })
        // }
      },
      columnFilterFn: function (data, filterString) {
        const x = parseInt(filterString)
        return data == x
        // return x
        // return data >= x - 5 && data <= x + 5
      },
    },
  }
</script>

<style></style>
