<template>
  <div class="container-fluid">
    <div class="row row-cols-1">
      <div class="col-12">
        <div class="row row-cols-1 mt-5">
          <div class="col">
            <div class="alert alert-danger" role="alert">網站服務調整 (2025/3/30起) <a href="https://www.facebook.com/just.bk.me/posts/967596972205081" target="_blank">見詳細公告</a></div>
          </div>
          <div class="col">
            <div class="card border border-0">
              <div class="card-body">
                <SearchFromPast @query="initChart"></SearchFromPast>
              </div>
            </div>
          </div>
        </div>
        <div class="col" v-if="!showSection">
          <div class="alert alert-warning" role="alert">
            <ol class="m-0">
              <li class="mb-1">資料蒐集起始時間：2023/1/16，意即要這個時間點以後才有資料，可查詢日期範圍：2023/1/16 ~ 2025/10/25</li>
              <li class="mb-1">此處也可以查詢泰國獅子航空(SL)、泰國越捷航空(VZ)、馬來西亞峇迪航空(OD)</li>
              <!-- <li class="mb-1">如果要看「去年同天」、「去年同星期」的資料，"建議" 查詢日期可設定 2024/5/1 以後</li> -->
              <li class="mb-1">本站將盡力提供正確之資訊，惟本網站對於所載資料之完整性、即時性和正確性不做任何擔保</li>
            </ol>
          </div>
        </div>
        <div class="col" v-if="showSection">
          <div class="alert alert-warning" role="alert">
            <ol class="m-0">
              <li class="mb-1">所提供的票價資訊均是建立在「直飛，台灣出發的來回且大人人數是 1 位」的情況下取得</li>
              <li class="mb-1">除非有另外說明，不然票價單位一律都是「單程未稅價，新台幣(TWD)」，票種也一律都是基本票種（也就是不包含任何附加服務項目）</li>
              <li class="mb-1">出發日前的定義，如果欲查詢的日期是今天(含)以後的日期，會以今天的日期去回推，但如果是昨天以前的日期，則會以欲查詢日期去回推</li>
              <li class="mb-1">此處呈現的票價軌跡均為完整呈現</li>
              <li class="mb-1">部份業者在外站(非台灣出發航點) 出發定價上面，由於會受匯率及銷售政策影響，對於新台幣轉換上不一定會是等值，還請留意</li>
              <li class="mb-1">本站將盡力提供正確之資訊，惟本網站對於所載資料之完整性、即時性和正確性不做任何擔保</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mb-4 bg-light rounded" v-if="showSection">
      <div class="d-inline-flex align-items-center mb-3 mt-1 flex-wrap"><span class="bg-IT mx-2"></span>IT 台灣虎航 <span class="bg-MM mx-2"></span>MM 樂桃航空 <span class="bg-TR mx-2"></span>TR 酷航 <span class="bg-7C mx-2"></span>7C 濟州航空 <span class="bg-BX mx-2"></span>BX 釜山航空 <span class="bg-TW mx-2"></span>TW 德威航空 <span class="bg-LJ mx-2"></span>LJ 真航空 <span class="bg-SL mx-2"></span>SL 泰國獅子航空 <span class="bg-VZ mx-2"></span>VZ 泰國越捷航空 <span class="bg-OD mx-2"></span>OD 馬來西亞峇迪航空 <span class="bg-FD mx-2"></span>FD 泰國亞洲航空<span class="bg-VJ mx-2"></span>VJ 越捷航空</div>
      <h2 class="display-6 text-center mb-3 mt-1">進階查詢 - 查詢結果</h2>
      <div class="table-wrap table-responsive">
        <table class="table table-borderless table-striped">
          <thead>
            <tr class="p-0">
              <td class="text-center w100 p-0 py-2 align-middle" scope="col" colspan="9">
                <b class="text-muted">
                  <h3>
                    <p class="text-primary">{{ oneway_maintitle }}</p>
                  </h3>
                  <small>{{ oneway_subtitle }}</small></b
                >
              </td>
            </tr>
            <tr>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col" rowspan="2">
                <b class="text-muted">旅行日期</b>
              </td>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col" rowspan="2">
                <b class="text-muted">班次</b>
              </td>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col" rowspan="2">
                <b class="text-muted">第一筆</b>
              </td>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col" rowspan="2">
                <b class="text-muted">最近(最後)一筆</b>
              </td>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col" colspan="2">
                <b class="text-muted">以紀錄日期來看<br />出發日或過去前</b>
              </td>
              <!-- <td class="text-center w100 p-0 py-2 align-middle" scope="col" colspan="2">
                <b class="text-muted">以紀錄日期來看</b>
              </td> -->
              <td class="text-center w100 p-0 py-2 align-middle" scope="col" rowspan="2">
                <b class="text-muted">均價</b>
              </td>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col" rowspan="2">
                <b class="text-muted">中位數</b>
              </td>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col" rowspan="2">
                <b class="text-muted">價格歷程</b>
              </td>
            </tr>
            <tr>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                <b class="text-muted">0~30天均價</b>
              </td>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                <b class="text-muted">45~60天均價</b>
              </td>
            </tr>
          </thead>
          <tbody class="table-group-divider" v-if="rows._get_first_last.length !== 0">
            <tr class="border-bottom bg-white" v-for="(item, key) in rows._get_first_last" :key="key">
              <td class="text-center align-middle">
                {{ item._departureDate }} <small>({{ item._departureDate | getWeekDay }})</small>
              </td>
              <td class="text-center align-middle">
                <div class="d-inline-flex align-items-center">
                  <span :class="'bg-' + item._airline + ' mx-2'"></span>
                  <div class="flex-column">
                    <div class="boxItem">
                      <!-- <h5>{{ item.departureDate }}</h5> -->
                      出發 {{ item._departureTime }}
                    </div>
                    <div class="line"></div>
                    <div class="boxItem">
                      抵達 {{ item._arrivalTime }}
                      <small v-if="item._departureDate != item._arrivalDate">(+1)</small>
                    </div>
                    <!-- <h4><i class="bi bi-bookmark-plus"></i></h4> -->
                    <!-- <h5> -->
                    <div style="word-wrap: break-word">
                      <!-- {{ item.airlineName }} -->
                      <span class="fs-4">{{ item._id }}</span>
                    </div>
                    <!-- </h5> -->
                  </div>
                </div>
                <!-- {{ item._id }} -->
              </td>
              <td class="text-center align-middle" v-if="item._airline != 'SL' && item._airline != '7C' && item._airline != 'BX' && item._airline != 'TW' && item._airline != 'OD' && item._airline != 'LJ'">
                {{ Number(item._first.fare1Amount).toLocaleString() }}<br /><small>{{ item._first.queryDate }}</small>
              </td>
              <td class="text-center align-middle" v-else>
                <template v-if="item._airline == 'BX'">
                  {{ Number(item._first.fare3Amount).toLocaleString() }} <font-awesome-icon icon="fa-solid fa-suitcase" /><br /><small>{{ item._first.queryDate }}</small>
                </template>
                <template v-else>
                  {{ Number(item._first.fare2Amount).toLocaleString() }} <font-awesome-icon icon="fa-solid fa-suitcase" /><br /><small>{{ item._first.queryDate }}</small>
                </template>
              </td>
              <td class="text-center align-middle" v-if="item._airline != 'SL' && item._airline != '7C' && item._airline != 'BX' && item._airline != 'TW' && item._airline != 'OD' && item._airline != 'LJ'">
                <span class="fs-4"
                  ><B>{{ Number(item._last.fare1Amount).toLocaleString() }}</B></span
                ><br /><small>{{ item._last.queryDate }}</small>
              </td>
              <td class="text-center align-middle" v-else>
                <template v-if="item._airline == 'BX'">
                  <span class="fs-4"
                    ><B>{{ Number(item._last.fare3Amount).toLocaleString() }}</B> <font-awesome-icon icon="fa-solid fa-suitcase" /></span
                  ><br /><small>{{ item._last.queryDate }}</small>
                </template>
                <template v-else>
                  <span class="fs-4"
                    ><B>{{ Number(item._last.fare2Amount).toLocaleString() }}</B> <font-awesome-icon icon="fa-solid fa-suitcase" /></span
                  ><br /><small>{{ item._last.queryDate }}</small>
                </template>
              </td>

              <td class="text-center align-middle">{{ item._id | getAvg(rows._get_avg30d, item._airline) }}</td>
              <td class="text-center align-middle">{{ item._id | getAvg(rows._get_avg45_60d, item._airline) }}</td>
              <td class="text-center align-middle" v-if="item._airline != 'SL' && item._airline != '7C' && item._airline != 'BX' && item._airline != 'TW' && item._airline != 'OD' && item._airline != 'LJ'">{{ Number(item._avg).toLocaleString() }}</td>
              <td class="text-center align-middle" v-else>{{ Number(item._avg2).toLocaleString() }}</td>

              <td class="text-center align-middle" v-if="item._airline != 'SL' && item._airline != '7C' && item._airline != 'BX' && item._airline != 'TW' && item._airline != 'OD' && item._airline != 'LJ'">{{ item._id | getMedian(rows._get_median) }}</td>
              <td class="text-center align-middle" v-else>{{ item._id | getMedian(rows._get_median2) }}</td>

              <td class="text-center align-middle"><button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#farehistoryModal" data-bs-whatever="@mdo" :data-bs-flight_no="item._id" :data-bs-dept_d="item._departureDate">檢視</button></td>
            </tr>
          </tbody>
          <tbody class="table-group-divider" v-else>
            <tr class="border-bottom bg-white">
              <td class="text-center align-middle" colspan="9">無資料或該日並無航班運行</td>
            </tr>
          </tbody>
          <tfoot v-if="rows._get_first_last.length !== 0">
            <tr>
              <td class="text-start align-middle" colspan="9">
                <p>
                  <small>{{ oneway_holiday }}</small
                  ><br />
                  <small>*如價格旁有標示 <font-awesome-icon icon="fa-solid fa-suitcase" />，意即該價格有包含免費託運行李</small><br />
                  <small>*僅供參考，上述資料均是建立在搜尋人數為１的情況</small>
                </p>
              </td>
              <!-- <td class="text-end align-middle" colspan="1"><img src="@/assets/jusbklogo.png" width="99" alt="jusbklogo" /></td> -->
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="row row-cols-1 row-cols-sm-2 g-2">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="table-wrap table-responsive">
                <table class="table table-borderless table-striped">
                  <thead>
                    <tr class="p-0">
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col" colspan="4">
                        <b class="text-muted">
                          <h3>
                            <p class="text-primary" v-html="sameday_oneway_maintitle"></p>
                          </h3>
                          <small>{{ sameday_oneway_subtitle }}</small></b
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                        <b class="text-muted">旅行日期</b>
                      </td>
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                        <b class="text-muted">班次</b>
                      </td>
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                        <b class="text-muted">整體均價</b>
                      </td>
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                        <b class="text-muted">價格紀錄</b>
                      </td>
                    </tr>
                  </thead>
                  <tbody class="table-group-divider" v-if="rows._get_last_year_by_sameday.length !== 0">
                    <tr class="border-bottom bg-white" v-for="(item, key) in rows._get_last_year_by_sameday" :key="key">
                      <td class="text-center align-middle">
                        {{ item._departureDate }} <small>({{ item._departureDate | getWeekDay }})</small>
                      </td>
                      <td class="text-center align-middle">
                        <div class="d-inline-flex align-items-center">
                          <span :class="'bg-' + item._airline + ' mx-2'"></span>
                          {{ item._id }}
                        </div>
                      </td>
                      <td class="text-center align-middle">
                        {{ Number(item._fare1Amount).toLocaleString() }}
                      </td>
                      <td class="text-center align-middle">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#farehistoryModal" data-bs-whatever="@mdo" :data-bs-flight_no="item._id" :data-bs-dept_d="item._departureDate">檢視</button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody class="table-group-divider" v-else>
                    <tr class="border-bottom bg-white">
                      <td class="text-center align-middle" colspan="4">無資料或該日並無航班運行</td>
                    </tr>
                  </tbody>
                  <tfoot v-if="rows._get_last_year_by_sameday.length !== 0">
                    <tr>
                      <td class="text-start align-middle" colspan="4">
                        <p>
                          <small>{{ sameday_oneway_holiday }}</small
                          ><br />
                          <small>*僅供參考，上述資料均是建立在搜尋人數為１的情況</small>
                        </p>
                      </td>
                      <!-- <td class="text-end align-middle" colspan="1"><img src="@/assets/jusbklogo.png" width="99" alt="jusbklogo" /></td> -->
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="table-wrap table-responsive">
                <table class="table table-borderless table-striped">
                  <thead>
                    <tr class="p-0">
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col" colspan="4">
                        <b class="text-muted">
                          <h3>
                            <p class="text-primary" v-html="sameweek_oneway_maintitle"></p>
                          </h3>
                          <small>{{ sameweek_oneway_subtitle }}</small></b
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                        <b class="text-muted">旅行日期</b>
                      </td>
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                        <b class="text-muted">班次</b>
                      </td>
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                        <b class="text-muted">整體均價</b>
                      </td>
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                        <b class="text-muted">價格紀錄</b>
                      </td>
                    </tr>
                  </thead>
                  <tbody class="table-group-divider" v-if="rows._get_last_year_by_sameweek.length !== 0">
                    <tr class="border-bottom bg-white" v-for="(item, key) in rows._get_last_year_by_sameweek" :key="key">
                      <td class="text-center align-middle">
                        {{ item._departureDate }} <small>({{ item._departureDate | getWeekDay }})</small>
                      </td>
                      <td class="text-center align-middle">
                        <div class="d-inline-flex align-items-center">
                          <span :class="'bg-' + item._airline + ' mx-2'"></span>
                          {{ item._id }}
                        </div>
                      </td>
                      <td class="text-center align-middle">
                        {{ Number(item._fare1Amount).toLocaleString() }}
                      </td>
                      <td class="text-center align-middle"><button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#farehistoryModal" data-bs-whatever="@mdo" :data-bs-flight_no="item._id" :data-bs-dept_d="item._departureDate">檢視</button></td>
                    </tr>
                  </tbody>
                  <tbody class="table-group-divider" v-else>
                    <tr class="border-bottom bg-white">
                      <td class="text-center align-middle" colspan="4">無資料或該日並無航班運行</td>
                    </tr>
                  </tbody>
                  <tfoot v-if="rows._get_last_year_by_sameweek.length !== 0">
                    <tr>
                      <td class="text-start align-middle" colspan="4">
                        <p>
                          <small>{{ sameweek_oneway_holiday }}</small
                          ><br />
                          <small>*僅供參考，上述資料均是建立在搜尋人數為１的情況</small>
                        </p>
                      </td>
                      <!-- <td class="text-end align-middle" colspan="1"><img src="@/assets/jusbklogo.png" width="99" alt="jusbklogo" /></td> -->
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr v-if="q.trip_type === 'RT'" />

      <div class="table-wrap table-responsive" v-if="q.trip_type === 'RT'">
        <table class="table table-borderless table-striped">
          <thead>
            <tr class="p-0">
              <td class="text-center w100 p-0 py-2 align-middle" scope="col" colspan="9">
                <b class="text-muted">
                  <h3>
                    <p class="text-primary" v-html="return_maintitle"></p>
                  </h3>
                  <small>{{ return_subtitle }}</small></b
                >
              </td>
            </tr>
            <tr>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col" rowspan="2">
                <b class="text-muted">旅行日期</b>
              </td>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col" rowspan="2">
                <b class="text-muted">班次</b>
              </td>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col" rowspan="2">
                <b class="text-muted">第一筆</b>
              </td>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col" rowspan="2">
                <b class="text-muted">最近(最後)一筆</b>
              </td>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col" colspan="2">
                <b class="text-muted">以紀錄日期來看<br />出發日或過去前</b>
              </td>
              <!-- <td class="text-center w100 p-0 py-2 align-middle" scope="col" colspan="2">
                <b class="text-muted">以紀錄日期來看</b>
              </td> -->
              <td class="text-center w100 p-0 py-2 align-middle" scope="col" rowspan="2">
                <b class="text-muted">均價</b>
              </td>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col" rowspan="2">
                <b class="text-muted">中位數</b>
              </td>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col" rowspan="2">
                <b class="text-muted">價格歷程</b>
              </td>
            </tr>
            <tr>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                <b class="text-muted">0~30天均價</b>
              </td>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                <b class="text-muted">45~60天均價</b>
              </td>
            </tr>
          </thead>
          <tbody class="table-group-divider" v-if="rows2._get_first_last.length !== 0">
            <tr class="border-bottom bg-white" v-for="(item, key) in rows2._get_first_last" :key="key">
              <td class="text-center align-middle">
                {{ item._departureDate }} <small>({{ item._departureDate | getWeekDay }})</small>
              </td>
              <td class="text-center align-middle">
                <div class="d-inline-flex align-items-center">
                  <span :class="'bg-' + item._airline + ' mx-2'"></span>
                  <div class="flex-column">
                    <div class="boxItem">
                      <!-- <h5>{{ item.departureDate }}</h5> -->
                      出發 {{ item._departureTime }}
                    </div>
                    <div class="line"></div>
                    <div class="boxItem">
                      抵達 {{ item._arrivalTime }}
                      <small v-if="item._departureDate != item._arrivalDate">(+1)</small>
                    </div>
                    <!-- <h4><i class="bi bi-bookmark-plus"></i></h4> -->
                    <!-- <h5> -->
                    <div style="word-wrap: break-word">
                      <!-- {{ item.airlineName }} -->
                      <span class="fs-4">{{ item._id }}</span>
                    </div>
                    <!-- </h5> -->
                  </div>
                </div>
              </td>
              <td class="text-center align-middle" v-if="item._airline != 'SL' && item._airline != '7C' && item._airline != 'BX' && item._airline != 'TW' && item._airline != 'OD' && item._airline != 'LJ'">
                {{ Number(item._first.fare1Amount).toLocaleString() }}<br /><small>{{ item._first.queryDate }}</small>
              </td>
              <td class="text-center align-middle" v-else>
                <template v-if="item._airline == 'BX'">
                  {{ Number(item._first.fare3Amount).toLocaleString() }} <font-awesome-icon icon="fa-solid fa-suitcase" /><br /><small>{{ item._first.queryDate }}</small>
                </template>
                <template v-else>
                  {{ Number(item._first.fare2Amount).toLocaleString() }} <font-awesome-icon icon="fa-solid fa-suitcase" /><br /><small>{{ item._first.queryDate }}</small>
                </template>
              </td>
              <td class="text-center align-middle" v-if="item._airline != 'SL' && item._airline != '7C' && item._airline != 'BX' && item._airline != 'TW' && item._airline != 'OD' && item._airline != 'LJ'">
                <span class="fs-4"
                  ><B>{{ Number(item._last.fare1Amount).toLocaleString() }}</B></span
                ><br /><small>{{ item._last.queryDate }}</small>
              </td>
              <td class="text-center align-middle" v-else>
                <template v-if="item._airline == 'BX'">
                  <span class="fs-4"
                    ><B>{{ Number(item._last.fare3Amount).toLocaleString() }}</B> <font-awesome-icon icon="fa-solid fa-suitcase" /></span
                  ><br /><small>{{ item._last.queryDate }}</small>
                </template>
                <template v-else>
                  <span class="fs-4"
                    ><B>{{ Number(item._last.fare2Amount).toLocaleString() }}</B> <font-awesome-icon icon="fa-solid fa-suitcase" /></span
                  ><br /><small>{{ item._last.queryDate }}</small>
                </template>
              </td>

              <td class="text-center align-middle">{{ item._id | getAvg(rows2._get_avg30d, item._airline) }}</td>
              <td class="text-center align-middle">{{ item._id | getAvg(rows2._get_avg45_60d, item._airline) }}</td>
              <td class="text-center align-middle" v-if="item._airline != 'SL' && item._airline != '7C' && item._airline != 'BX' && item._airline != 'LJ'">{{ Number(item._avg).toLocaleString() }}</td>
              <td class="text-center align-middle" v-else>{{ Number(item._avg2).toLocaleString() }}</td>

              <td class="text-center align-middle" v-if="item._airline != 'SL' && item._airline != '7C' && item._airline != 'BX' && item._airline != 'LJ'">{{ item._id | getMedian(rows2._get_median) }}</td>
              <td class="text-center align-middle" v-else>{{ item._id | getMedian(rows2._get_median2) }}</td>
              <td class="text-center align-middle"><button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#farehistoryModal" data-bs-whatever="@mdo" :data-bs-flight_no="item._id" :data-bs-dept_d="item._departureDate">檢視</button></td>
            </tr>
          </tbody>
          <tbody class="table-group-divider" v-else>
            <tr class="border-bottom bg-white">
              <td class="text-center align-middle" colspan="9">無資料或該日並無航班運行</td>
            </tr>
          </tbody>
          <tfoot v-if="rows2._get_first_last.length !== 0">
            <tr>
              <td class="text-start align-middle" colspan="9">
                <p>
                  <small>{{ return_holiday }}</small
                  ><br />
                  <small>*如價格旁有標示 <font-awesome-icon icon="fa-solid fa-suitcase" />，意即該價格有包含免費託運行李</small><br />
                  <small>*僅供參考，上述資料均是建立在搜尋人數為１的情況</small>
                </p>
              </td>
              <!-- <td class="text-end align-middle" colspan="1"><img src="@/assets/jusbklogo.png" width="99" alt="jusbklogo" /></td> -->
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="row row-cols-1 row-cols-sm-2 g-2" v-if="q.trip_type === 'RT'">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="table-wrap table-responsive">
                <table class="table table-borderless table-striped">
                  <thead>
                    <tr class="p-0">
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col" colspan="4">
                        <b class="text-muted">
                          <h3>
                            <p class="text-primary" v-html="sameday_return_maintitle"></p>
                          </h3>
                          <small>{{ sameday_return_subtitle }}</small></b
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                        <b class="text-muted">旅行日期</b>
                      </td>
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                        <b class="text-muted">班次</b>
                      </td>
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                        <b class="text-muted">整體均價</b>
                      </td>
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                        <b class="text-muted">價格紀錄</b>
                      </td>
                    </tr>
                  </thead>
                  <tbody class="table-group-divider" v-if="rows2._get_last_year_by_sameday.length !== 0">
                    <tr class="border-bottom bg-white" v-for="(item, key) in rows2._get_last_year_by_sameday" :key="key">
                      <td class="text-center align-middle">
                        {{ item._departureDate }} <small>({{ item._departureDate | getWeekDay }})</small>
                      </td>
                      <td class="text-center align-middle">
                        <div class="d-inline-flex align-items-center">
                          <span :class="'bg-' + item._airline + ' mx-2'"></span>
                          {{ item._id }}
                        </div>
                      </td>
                      <td class="text-center align-middle">
                        {{ Number(item._fare1Amount).toLocaleString() }}
                      </td>
                      <td class="text-center align-middle"><button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#farehistoryModal" data-bs-whatever="@mdo" :data-bs-flight_no="item._id" :data-bs-dept_d="item._departureDate">檢視</button></td>
                    </tr>
                  </tbody>
                  <tbody class="table-group-divider" v-else>
                    <tr class="border-bottom bg-white">
                      <td class="text-center align-middle" colspan="4">無資料或該日並無航班運行</td>
                    </tr>
                  </tbody>
                  <tfoot v-if="rows2._get_last_year_by_sameday.length !== 0">
                    <tr>
                      <td class="text-start align-middle" colspan="4">
                        <p>
                          <small>{{ sameday_return_holiday }}</small
                          ><br />
                          <small>*僅供參考，上述資料均是建立在搜尋人數為１的情況</small>
                        </p>
                      </td>
                      <!-- <td class="text-end align-middle" colspan="1"><img src="@/assets/jusbklogo.png" width="99" alt="jusbklogo" /></td> -->
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="table-wrap table-responsive">
                <table class="table table-borderless table-striped">
                  <thead>
                    <tr class="p-0">
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col" colspan="4">
                        <b class="text-muted">
                          <h3>
                            <p class="text-primary" v-html="sameweek_return_maintitle"></p>
                          </h3>
                          <small>{{ sameweek_return_subtitle }}</small></b
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                        <b class="text-muted">旅行日期</b>
                      </td>
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                        <b class="text-muted">班次</b>
                      </td>
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                        <b class="text-muted">整體均價</b>
                      </td>
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                        <b class="text-muted">價格紀錄</b>
                      </td>
                    </tr>
                  </thead>
                  <tbody class="table-group-divider" v-if="rows2._get_last_year_by_sameweek.length !== 0">
                    <tr class="border-bottom bg-white" v-for="(item, key) in rows2._get_last_year_by_sameweek" :key="key">
                      <td class="text-center align-middle">
                        {{ item._departureDate }} <small>({{ item._departureDate | getWeekDay }})</small>
                      </td>
                      <td class="text-center align-middle">
                        <div class="d-inline-flex align-items-center">
                          <span :class="'bg-' + item._airline + ' mx-2'"></span>
                          {{ item._id }}
                        </div>
                      </td>
                      <td class="text-center align-middle">
                        {{ Number(item._fare1Amount).toLocaleString() }}
                      </td>
                      <td class="text-center align-middle"><button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#farehistoryModal" data-bs-whatever="@mdo" :data-bs-flight_no="item._id" :data-bs-dept_d="item._departureDate">檢視</button></td>
                    </tr>
                  </tbody>
                  <tbody class="table-group-divider" v-else>
                    <tr class="border-bottom bg-white">
                      <td class="text-center align-middle" colspan="4">無資料或該日並無航班運行</td>
                    </tr>
                  </tbody>
                  <tfoot v-if="rows2._get_last_year_by_sameweek.length !== 0">
                    <tr>
                      <td class="text-start align-middle" colspan="4">
                        <p>
                          <small>{{ sameweek_return_holiday }}</small
                          ><br />
                          <small>*僅供參考，上述資料均是建立在搜尋人數為１的情況，由於有時效性問題，建議要留意一下資料取得時間</small>
                        </p>
                      </td>
                      <!-- <td class="text-end align-middle" colspan="1"><img src="@/assets/jusbklogo.png" width="99" alt="jusbklogo" /></td> -->
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade modal-xl" id="farehistoryModal" tabindex="-1" aria-labelledby="farehistoryModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div id="chart">
              <apexchart ref="initChart" type="line" height="300" :options="chartOptions" :series="series"></apexchart>
            </div>
            <div id="chart-line">
              <apexchart ref="initChart2" type="line" height="130" :options="chartOptionsLine" :series="series"></apexchart>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">關閉</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="css" src="@/css/a.css" scoped></style>
<script>
  import SearchFromPast from "./SearchFrom_past.vue"
  import axios from "axios"
  import moment from "moment"
  import Cookies from "js-cookie"
  // import GlobalVar from "@/mixins/global_var.js"
  export default {
    name: "VueSearchHistory",
    components: {
      SearchFromPast,
      // Loading,
    },
    created() {
      // alert("error")
    },
    mounted() {
      sessionStorage.setItem("page", "AdvSearch")
      Cookies.set("page", "AdvSearch", { secure: true })
      // const login_token = Cookies.get("login_token")
      // this.checkLoginTokeVaild(login_token)
      // if (!sessionStorage.getItem("is_email_confirm")) {
      //   this.$alert("10/1 起本功能僅提供通過帳號驗證之會員使用，如尚未完成者還請先完成驗證後再使用", "系統訊息", "error").then(() => {
      //     window.location.href = `/Login`
      //   })
      // }

      if (sessionStorage.getItem("role_type") !== "Sponsored") {
        this.$alert("2024/11/1 起本功能僅提供付費會員使用", "系統訊息", "error").then(() => {
          window.location.href = `/`
        })
      }

      const farehistoryModal = document.getElementById("farehistoryModal")
      if (farehistoryModal) {
        farehistoryModal.addEventListener("show.bs.modal", (event) => {
          const button = event.relatedTarget
          const flight_no = button.getAttribute("data-bs-flight_no")
          const dept_d = button.getAttribute("data-bs-dept_d")
          const me = this

          let qurl = `${this.$apiUrl}gethistoryfare/`
          if (flight_no.substr(0, 2) === "SL" || flight_no.substr(0, 2) === "OD" || flight_no.substr(0, 2) === "7C" || flight_no.substr(0, 2) === "TW" || flight_no.substr(0, 2) === "LJ") {
            qurl = `${this.$apiUrl}gethistoryfare2/`
          }
          if (flight_no.substr(0, 2) === "BX") {
            qurl = `${this.$apiUrl}gethistoryfare2/`
          }
          axios({
            method: "GET",
            url: `${qurl}${dept_d}/${flight_no}`,
            headers: {
              Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
            },
          }).then((response) => {
            me.series.data = response.data[0]
            me.$refs.initChart.updateSeries([
              {
                name: "票價",
                type: "line",
                data: response.data[0],
              },
            ])

            me.$refs.initChart2.updateSeries([
              {
                name: "票價",
                type: "line",
                data: response.data[0],
              },
            ])

            me.chartOptions = {
              ...me.chartOptions,
              ...{
                // labels: response.data[0][1],
                // colors: response.data[0][2],
                // legend: {
                //   itemMargin: {
                //     vertical: 15,
                //   },
                // },
                title: {
                  text: `${flight_no} 於出發日 ${dept_d} 歷史價格紀錄`,
                  style: {
                    fontSize: "20px",
                  },
                },
                subtitle: {
                  text: "所提供的票價資訊均是建立在「台灣出發的來回且大人人數是 1 位」的情況",
                },
              },
            }

            me.chartOptionsLine = {
              ...me.chartOptionsLine,
              ...{
                chart: {
                  selection: {
                    xaxis: {
                      min: new Date(response.data[1].end).getTime(),
                      max: new Date(response.data[1].start).getTime(),
                    },
                  },
                },
              },
            }
          })
        })
      }
    },
    // mixins: [GlobalVar],
    data: function () {
      return {
        series: [
          {
            name: "",
            data: [],
          },
        ],
        chartOptionsLine: {
          chart: {
            id: "chart1",
            height: 130,
            type: "line",
            brush: {
              target: "chart2",
              enabled: true,
            },
            stroke: {
              curve: "straight",
            },
            selection: {
              enabled: true,
              // xaxis: {
              //   min: new Date("19 Jun 2023").getTime(),
              //   max: new Date("14 Aug 2023").getTime(),
              // },
            },
          },
          colors: ["#008FFB"],
          xaxis: {
            type: "datetime",
            tooltip: {
              enabled: false,
            },
          },
          yaxis: {
            // tickAmount: 2,
          },
        },
        chartOptions: {
          chart: {
            id: "chart2",
            height: 300,
            type: "line",
            dropShadow: {
              enabled: true,
              color: "#000",
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2,
            },
            // toolbar: {
            //   show: false,
            // },
            zoom: {
              type: "x",
              enabled: true,
              autoScaleYaxis: true,
            },
          },
          noData: {
            text: "Loading...",
          },
          responsive: [
            {
              breakpoint: 1000,
              options: {
                title: {
                  style: {
                    fontSize: "12px",
                  },
                },
              },
            },
          ],
          colors: ["#77B6EA", "#545454"],
          dataLabels: {
            enabled: true,
          },
          stroke: {
            curve: "smooth",
          },
          title: {
            // text: "",
            align: "left",
          },
          grid: {
            borderColor: "#e7e7e7",
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          markers: {
            size: 1,
          },
          xaxis: {
            type: "datetime",
            title: {
              text: "紀錄日期",
              // offsetY: 75,
            },
          },
          yaxis: {
            title: {
              text: "票價(單程未稅)",
            },
            // min: 5,
            // max: 40,
          },
          legend: {
            position: "top",
            horizontalAlign: "right",
            floating: true,
            // offsetY: -25,
            // offsetX: -5,
          },
        },
        form: {
          email: "",
          name: "",
          // food: null,
          // checked: []
        },
        showCreateSection: false,
        q: {
          dept: "",
          arri: "",
          trip_type: "",
        },
        rows: [],
        rows2: [],
        // apiUrl: "https://justbk-api-4cqv3jfjdq-de.a.run.app/",
        // apiUrl: "http://localhost:8000/",
        oneway_maintitle: "",
        oneway_subtitle: "",
        oneway_holiday: "",
        sameday_oneway_maintitle: "",
        sameday_oneway_subtitle: "",
        sameday_oneway_holiday: "",
        sameweek_oneway_maintitle: "",
        sameweek_oneway_subtitle: "",
        sameweek_oneway_holiday: "",
        return_maintitle: "",
        return_subtitle: "",
        return_holiday: "",
        sameday_return_maintitle: "",
        sameday_return_subtitle: "",
        sameday_return_holiday: "",
        sameweek_return_maintitle: "",
        sameweek_return_subtitle: "",
        sameweek_return_holiday: "",
      }
    },
    computed: {
      showSection() {
        return this.showCreateSection
      },
    },
    methods: {
      // checkLoginTokeVaild(login_token) {
      //   const me = this
      //   const json_d = { token: login_token }

      //   axios({
      //     method: "POST",
      //     url: `${me.$apiUrl2}checkLoginTokeVaild`,
      //     data: json_d,
      //     headers: {
      //       Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
      //       "Content-Type": "application/json",
      //     },
      //   }).then(function (response) {
      //     const res = response.data

      //     if (res.status === 201) {
      //       if (res.statusText === "Select") {
      //         me.user_uuid = res.data.id
      //         sessionStorage.setItem("uuid", res.data.id)
      //         sessionStorage.setItem("is_used", res.data.user_take.is_used)
      //         sessionStorage.setItem("is_email_confirm", res.data.user_take.is_email_confirm)
      //         Cookies.set("uuid", res.data.id, { secure: true })
      //         Cookies.set("is_used", res.data.user_take.is_used, { secure: true })
      //         Cookies.set("is_email_confirm", res.data.user_take.is_email_confirm, { secure: true })

      //         if (me.$route.name === "login") {
      //           window.location.href = `/AdvSearch`
      //         }
      //       }
      //     }
      //     if (res.status === 503) {
      //       me.main_page_switch = false
      //       me.user_uuid = null
      //       window.location.href = `/Login`
      //     }
      //   })
      // },
      async handlerLogin() {},
      showModal() {},
      initChart(val) {
        this.showCreateSection = true
        this.seen3 = false
        const query_d = val.dateRange2
        const dept = val.countryName1.split("|")[1]
        const arri = val.cityName1.split("|")[1]
        const trip_type = val.selectedItems1

        const me = this
        me.q.dept = dept
        me.q.arri = arri
        me.q.trip_type = trip_type

        me.rows = []
        me.rows2 = []

        me.oneway_maintitle = ""
        me.oneway_subtitle = ""
        me.oneway_holiday = ""
        me.sameday_oneway_maintitle = ""
        me.sameday_oneway_subtitle = ""
        me.sameday_oneway_holiday = ""
        me.sameweek_oneway_maintitle = ""
        me.sameweek_oneway_subtitle = ""
        me.sameweek_oneway_holiday = ""

        me.return_maintitle = ""
        me.return_subtitle = ""
        me.return_holiday = ""
        me.sameday_return_maintitle = ""
        me.sameday_return_subtitle = ""
        me.sameday_return_holiday = ""
        me.sameweek_return_maintitle = ""
        me.sameweek_return_subtitle = ""
        me.sameweek_return_holiday = ""

        let qurl = `${this.$apiUrl}advstat/`,
          dept_d,
          arri_d

        if (trip_type === "OW") {
          dept_d = query_d
          arri_d = query_d
          axios({
            method: "GET",
            url: `${qurl}${dept_d}/${dept}/${arri}`,
            headers: {
              Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
            },
          }).then((response) => {
            me.rows = response.data

            me.oneway_maintitle = response.data._get_first_last_config.maintitle
            me.oneway_subtitle = response.data._get_first_last_config.subtitle
            me.oneway_holiday = response.data._get_first_last_config.holiday

            me.sameday_oneway_maintitle = response.data._get_last_year_by_sameday_config.maintitle
            me.sameday_oneway_subtitle = response.data._get_last_year_by_sameday_config.subtitle
            me.sameday_oneway_holiday = response.data._get_last_year_by_sameday_config.holiday

            me.sameweek_oneway_maintitle = response.data._get_last_year_by_sameweek_config.maintitle
            me.sameweek_oneway_subtitle = response.data._get_last_year_by_sameweek_config.subtitle
            me.sameweek_oneway_holiday = response.data._get_last_year_by_sameweek_config.holiday
          })
        }

        if (trip_type === "RT") {
          dept_d = query_d[0]
          arri_d = query_d[1]
          axios({
            method: "GET",
            url: `${qurl}${dept_d}/${dept}/${arri}`,
            headers: {
              Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
            },
          }).then((response) => {
            me.rows = response.data

            me.oneway_maintitle = response.data._get_first_last_config.maintitle
            me.oneway_subtitle = response.data._get_first_last_config.subtitle
            me.oneway_holiday = response.data._get_first_last_config.holiday

            me.sameday_oneway_maintitle = response.data._get_last_year_by_sameday_config.maintitle
            me.sameday_oneway_subtitle = response.data._get_last_year_by_sameday_config.subtitle
            me.sameday_oneway_holiday = response.data._get_last_year_by_sameday_config.holiday

            me.sameweek_oneway_maintitle = response.data._get_last_year_by_sameweek_config.maintitle
            me.sameweek_oneway_subtitle = response.data._get_last_year_by_sameweek_config.subtitle
            me.sameweek_oneway_holiday = response.data._get_last_year_by_sameweek_config.holiday
          })

          axios({
            method: "GET",
            url: `${qurl}${arri_d}/${arri}/${dept}`,
            headers: {
              Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
            },
          }).then((response) => {
            me.rows2 = response.data

            me.return_maintitle = response.data._get_first_last_config.maintitle
            me.return_subtitle = response.data._get_first_last_config.subtitle
            me.return_holiday = response.data._get_first_last_config.holiday

            me.sameday_return_maintitle = response.data._get_last_year_by_sameday_config.maintitle
            me.sameday_return_subtitle = response.data._get_last_year_by_sameday_config.subtitle
            me.sameday_return_holiday = response.data._get_last_year_by_sameday_config.holiday

            me.sameweek_return_maintitle = response.data._get_last_year_by_sameweek_config.maintitle
            me.sameweek_return_subtitle = response.data._get_last_year_by_sameweek_config.subtitle
            me.sameweek_return_holiday = response.data._get_last_year_by_sameweek_config.holiday
          })
        }
      },
    },
    filters: {
      getWeekDay: function (date) {
        const weeks = ["", "一", "二", "三", "四", "五", "六", "日"]
        return weeks[moment(date).isoWeekday()]
      },
      getMedian: function (_key, _json) {
        const d = JSON.parse(JSON.stringify(_json))
        let index = 0,
          entry
        for (index = 0; index < d.length; ++index) {
          entry = d[index]
          if (entry._id == _key) {
            return Number(entry._median).toLocaleString()
          }
        }
      },

      getAvg: function (_key, _json, _airline) {
        const d = JSON.parse(JSON.stringify(_json))
        let index = 0,
          entry
        for (index = 0; index < d.length; ++index) {
          entry = d[index]
          if (entry._id == _key) {
            if (_airline === "SL" || _airline === "7C" || _airline === "TW" || _airline === "OD") return Number(entry._avg2).toLocaleString()
            else if (_airline === "BX") return Number(entry._avg3).toLocaleString()
            else return Number(entry._avg).toLocaleString()
          }
        }
      },
    },
  }
</script>
