export default {
  data() {
    return {
      area_index: [
        {
          country: "台北(桃園)|TPE",
          city: ["札幌|CTS", "東京成田|NRT", "東京羽田|HND", "名古屋|NGO", "大阪|KIX", "沖繩|OKA", "-----", "首爾(仁川)|ICN", "釜山|PUS", "大邱|TAE", "濟州|CJU", "-----", "富國島|PQC"],
          // ],
        },
        // {
        //   country: "台中|RMQ",
        //   city: ["東京成田|NRT", "名古屋|NGO", "-----", "首爾(仁川)|ICN", "釜山|PUS", "-----", "澳門|MFM", "-----", "富國島|PQC"],
        // },
        {
          country: "高雄|KHH",
          city: [
            "東京成田|NRT",
            "名古屋|NGO",
            "大阪|KIX",
            // "岡山|OKJ",
            // "福岡|FUK",
            "沖繩|OKA",
            "-----",
            "首爾(仁川)|ICN",
            "首爾(金浦)|GMP",
            "釜山|PUS",
            // "-----",
            // "澳門|MFM",
            "-----",
            "曼谷(廊曼)|DMK",
            "-----",
            "富國島|PQC",
            "沖繩|OKA",
          ],
        },
        {
          country: "-----",
          city: [],
        },
        {
          country: "札幌|CTS",
          city: ["台北(桃園)|TPE"],
        },
        // {
        //   country: "旭川|AKJ",
        //   city: ["台北(桃園)|TPE"],
        // },
        // {
        //   country: "函館|HKD",
        //   city: ["台北(桃園)|TPE"],
        // },
        // {
        //   country: "旭川|AKJ",
        //   city: ["台北(桃園)|TPE"],
        // },
        // {
        //   country: "秋田|AXT",
        //   city: ["台北(桃園)|TPE"],
        // },
        // {
        //   country: "花卷|HNA",
        //   city: ["台北(桃園)|TPE"],
        // },
        // {
        //   country: "福島|FKS",
        //   city: ["台北(桃園)|TPE"],
        // },
        // {
        //   country: "仙台|SDJ",
        //   city: ["台北(桃園)|TPE"],
        // },
        // {
        //   country: "茨城|IBR",
        //   city: ["台北(桃園)|TPE"],
        // },
        {
          country: "東京成田|NRT",
          city: ["台北(桃園)|TPE", "高雄|KHH"],
        },
        {
          country: "東京羽田|HND",
          city: ["台北(桃園)|TPE"],
        },
        // {
        //   country: "新潟|KIJ",
        //   city: ["台北(桃園)|TPE"],
        // },
        // {
        //   country: "小松|KMQ",
        //   city: ["台北(桃園)|TPE"],
        // },
        {
          country: "名古屋|NGO",
          city: ["台北(桃園)|TPE", "高雄|KHH"],
        },
        {
          country: "大阪|KIX",
          city: ["台北(桃園)|TPE", "高雄|KHH"],
        },
        // {
        //   country: "岡山|OKJ",
        //   city: ["台北(桃園)|TPE", "高雄|KHH"],
        // },
        // {
        //   country: "高知|KCZ",
        //   city: ["台北(桃園)|TPE"],
        // },
        // {
        //   country: "福岡|FUK",
        //   city: ["台北(桃園)|TPE", "高雄|KHH"],
        // },
        // {
        //   country: "佐賀|HSG",
        //   city: ["台北(桃園)|TPE"],
        // },
        {
          country: "沖繩|OKA",
          city: ["台北(桃園)|TPE", "高雄|KHH"],
        },
        {
          country: "-----",
          city: [],
        },
        {
          country: "首爾(仁川)|ICN",
          city: [
            "台北(桃園)|TPE",
            // "台中|RMQ",
            "高雄|KHH",
            // "沖繩|OKA"
          ],
        },
        {
          country: "首爾(金浦)|GMP",
          city: [
            // "台北(桃園)|TPE",
            "高雄|KHH",
            // "沖繩|OKA"
          ],
        },
        {
          country: "釜山|PUS",
          city: [
            "台北(桃園)|TPE",
            // "台中|RMQ",
            "高雄|KHH",
            // "沖繩|OKA"
          ],
        },
        {
          country: "大邱|TAE",
          city: [
            "台北(桃園)|TPE",
            // "高雄|KHH",
            // "沖繩|OKA"
          ],
        },
        {
          country: "濟州|CJU",
          city: [
            "台北(桃園)|TPE",
            // "高雄|KHH",
            // "沖繩|OKA"
          ],
        },
        // {
        //   country: "-----",
        //   city: [],
        // },
        // {
        //   country: "澳門|MFM",
        //   city: ["台北(桃園)|TPE", "台中|RMQ", "高雄|KHH"],
        // },
        {
          country: "-----",
          city: [],
        },
        {
          country: "曼谷(廊曼)|DMK",
          city: ["高雄|KHH"],
        },
        {
          country: "-----",
          city: [],
        },
        {
          country: "富國島|PQC",
          city: ["台北(桃園)|TPE", "台中|RMQ", "高雄|KHH"],
        },
        // {
        //   country: "-----",
        //   city: [],
        // },
        // {
        //   country: "普吉島|HKT",
        //   city: ["台北(桃園)|TPE"],
        // },
      ],

      area: [
        {
          country: "台北(桃園)|TPE",
          city: ["札幌|CTS", "旭川|AKJ", "函館|HKD", "秋田|AXT", "花卷|HNA", "福島|FKS", "仙台|SDJ", "茨城|IBR", "東京成田|NRT", "東京羽田|HND", "新潟|KIJ", "小松|KMQ", "名古屋|NGO", "大阪|KIX", "岡山|OKJ", "米子|YGJ", "高知|KCZ", "福岡|FUK", "佐賀|HSG", "大分|OIT", "宮崎|KMI", "沖繩|OKA", "-----", "首爾(仁川)|ICN", "釜山|PUS", "大邱|TAE", "濟州|CJU", "-----", "普吉島|HKT"],
          // ],
        },
        // {
        //   country: "台中|RMQ",
        //   city: ["東京成田|NRT", "名古屋|NGO", "-----", "首爾(仁川)|ICN", "釜山|PUS"],
        // },
        {
          country: "高雄|KHH",
          city: [
            "札幌|CTS",
            "東京成田|NRT",
            "名古屋|NGO",
            "大阪|KIX",
            "岡山|OKJ",
            "福岡|FUK",
            "沖繩|OKA",
            "-----",
            "首爾(仁川)|ICN",
            "首爾(金浦)|GMP",
            "釜山|PUS",
            // "-----",
            // "澳門|MFM",
            "-----",
            "曼谷(廊曼)|DMK",
            // "-----",
            // "富國島|PQC",
            // "沖繩|OKA"
          ],
        },
        {
          country: "-----",
          city: [],
        },
        {
          country: "札幌|CTS",
          city: ["台北(桃園)|TPE", "高雄|KHH"],
        },
        {
          country: "旭川|AKJ",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "函館|HKD",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "旭川|AKJ",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "秋田|AXT",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "花卷|HNA",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "福島|FKS",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "仙台|SDJ",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "茨城|IBR",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "東京成田|NRT",
          city: ["台北(桃園)|TPE", "高雄|KHH"],
        },
        {
          country: "東京羽田|HND",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "新潟|KIJ",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "小松|KMQ",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "名古屋|NGO",
          city: ["台北(桃園)|TPE", "高雄|KHH"],
        },
        {
          country: "大阪|KIX",
          city: ["台北(桃園)|TPE", "高雄|KHH"],
        },
        {
          country: "岡山|OKJ",
          city: ["台北(桃園)|TPE", "高雄|KHH"],
        },
        {
          country: "米子|YGJ",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "高知|KCZ",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "福岡|FUK",
          city: ["台北(桃園)|TPE", "高雄|KHH"],
        },
        {
          country: "佐賀|HSG",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "大分|OIT",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "宮崎|KMI",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "沖繩|OKA",
          city: ["台北(桃園)|TPE", "高雄|KHH"],
        },
        {
          country: "-----",
          city: [],
        },
        {
          country: "首爾(仁川)|ICN",
          city: [
            "台北(桃園)|TPE",
            // "台中|RMQ",
            "高雄|KHH",
            // "沖繩|OKA"
          ],
        },
        {
          country: "首爾(金浦)|GMP",
          city: [
            // "台北(桃園)|TPE",
            "高雄|KHH",
            // "沖繩|OKA"
          ],
        },
        {
          country: "釜山|PUS",
          city: [
            "台北(桃園)|TPE",
            // "台中|RMQ",
            "高雄|KHH",
            // "沖繩|OKA"
          ],
        },
        {
          country: "大邱|TAE",
          city: [
            "台北(桃園)|TPE",
            // "高雄|KHH",
            // "沖繩|OKA"
          ],
        },
        {
          country: "濟州|CJU",
          city: [
            "台北(桃園)|TPE",
            // "高雄|KHH",
            // "沖繩|OKA"
          ],
        },
        // {
        //   country: "-----",
        //   city: [],
        // },
        // {
        //   country: "澳門|MFM",
        //   city: ["台北(桃園)|TPE", "台中|RMQ", "高雄|KHH"],
        // },
        {
          country: "-----",
          city: [],
        },
        {
          country: "曼谷(廊曼)|DMK",
          city: ["高雄|KHH"],
        },
        // {
        //   country: "-----",
        //   city: [],
        // },
        // {
        //   country: "富國島|PQC",
        //   city: ["台北(桃園)|TPE", "台中|RMQ", "高雄|KHH"],
        // },
        {
          country: "-----",
          city: [],
        },
        {
          country: "普吉島|HKT",
          city: ["台北(桃園)|TPE"],
        },
      ],

      area4stat: [
        {
          country: "台北(桃園)|TPE",
          city: ["札幌|CTS", "旭川|AKJ", "函館|HKD", "秋田|AXT", "花卷|HNA", "福島|FKS", "仙台|SDJ", "茨城|IBR", "東京成田|NRT", "東京羽田|HND", "新潟|KIJ", "小松|KMQ", "名古屋|NGO", "大阪|KIX", "岡山|OKJ", "高知|KCZ", "福岡|FUK", "佐賀|HSG", "沖繩|OKA", "-----", "首爾(仁川)|ICN", "釜山|PUS", "大邱|TAE", "濟州|CJU", "-----", "澳門|MFM", "-----", "富國島|PQC"],
          // ],
        },
        {
          country: "台中|RMQ",
          city: ["東京成田|NRT", "名古屋|NGO", "-----", "首爾(仁川)|ICN", "釜山|PUS", "-----", "澳門|MFM"],
        },
        {
          country: "高雄|KHH",
          city: [
            "東京成田|NRT",
            "名古屋|NGO",
            "大阪|KIX",
            "岡山|OKJ",
            "福岡|FUK",
            "沖繩|OKA",
            "-----",
            "首爾(仁川)|ICN",
            "首爾(金浦)|GMP",
            "釜山|PUS",
            "-----",
            "澳門|MFM",
            "-----",
            "曼谷(廊曼)|DMK",
            // "沖繩|OKA"
          ],
        },
        {
          country: "-----",
          city: [],
        },
        {
          country: "札幌|CTS",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "旭川|AKJ",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "函館|HKD",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "旭川|AKJ",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "秋田|AXT",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "花卷|HNA",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "福島|FKS",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "仙台|SDJ",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "茨城|IBR",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "東京成田|NRT",
          city: ["台北(桃園)|TPE", "台中|RMQ", "高雄|KHH"],
        },
        {
          country: "東京羽田|HND",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "新潟|KIJ",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "小松|KMQ",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "名古屋|NGO",
          city: ["台北(桃園)|TPE", "台中|RMQ", "高雄|KHH"],
        },
        {
          country: "大阪|KIX",
          city: ["台北(桃園)|TPE", "高雄|KHH"],
        },
        {
          country: "岡山|OKJ",
          city: ["台北(桃園)|TPE", "高雄|KHH"],
        },
        {
          country: "高知|KCZ",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "福岡|FUK",
          city: ["台北(桃園)|TPE", "高雄|KHH"],
        },
        {
          country: "佐賀|HSG",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "沖繩|OKA",
          city: ["台北(桃園)|TPE", "高雄|KHH"],
        },
        {
          country: "-----",
          city: [],
        },
        {
          country: "首爾(仁川)|ICN",
          city: [
            "台北(桃園)|TPE",
            "台中|RMQ",
            "高雄|KHH",
            // "沖繩|OKA"
          ],
        },
        {
          country: "首爾(金浦)|GMP",
          city: [
            // "台北(桃園)|TPE",
            "高雄|KHH",
            // "沖繩|OKA"
          ],
        },
        {
          country: "釜山|PUS",
          city: [
            "台北(桃園)|TPE",
            "台中|RMQ",
            "高雄|KHH",
            // "沖繩|OKA"
          ],
        },
        {
          country: "大邱|TAE",
          city: [
            "台北(桃園)|TPE",
            // "高雄|KHH",
            // "沖繩|OKA"
          ],
        },
        {
          country: "濟州|CJU",
          city: [
            "台北(桃園)|TPE",
            // "高雄|KHH",
            // "沖繩|OKA"
          ],
        },
        {
          country: "-----",
          city: [],
        },
        {
          country: "澳門|MFM",
          city: ["台北(桃園)|TPE", "台中|RMQ", "高雄|KHH"],
        },
        {
          country: "-----",
          city: [],
        },
        {
          country: "曼谷(廊曼)|DMK",
          city: ["高雄|KHH"],
        },
        {
          country: "-----",
          city: [],
        },
        {
          country: "富國島|PQC",
          city: ["台北(桃園)|TPE"],
        },
        // {
        //   country: "-----",
        //   city: [],
        // },
        // {
        //   country: "普吉島|HKT",
        //   city: ["台北(桃園)|TPE"],
        // },
      ],

      area4IT_JPKR: [
        {
          country: "台北(桃園)|TPE",
          // city: ["札幌|CTS", "旭川|AKJ", "函館|HKD", "秋田|AXT", "花卷|HNA", "福島|FKS", "仙台|SDJ", "茨城|IBR", "東京成田|NRT", "東京羽田|HND", "新潟|KIJ", "小松|KMQ", "名古屋|NGO", "大阪|KIX", "岡山|OKJ", "米子|YGJ", "高知|KCZ", "福岡|FUK", "佐賀|HSG", "大分|OIT", "宮崎|KMI", "沖繩|OKA", "-----", "首爾(仁川)|ICN", "釜山|PUS", "大邱|TAE", "濟州|CJU", "-----", "澳門|MFM", "-----", "富國島|PQC", "-----", "普吉島|HKT"],
          // city: ["札幌|CTS", "旭川|AKJ", "函館|HKD", "秋田|AXT", "花卷|HNA", "福島|FKS", "仙台|SDJ", "茨城|IBR", "東京成田|NRT", "東京羽田|HND", "新潟|KIJ", "小松|KMQ", "名古屋|NGO", "大阪|KIX", "岡山|OKJ", "米子|YGJ", "高知|KCZ", "福岡|FUK", "佐賀|HSG", "大分|OIT", "宮崎|KMI", "沖繩|OKA", "-----", "首爾(仁川)|ICN", "釜山|PUS", "濟州|CJU"],

          city: ["札幌|CTS", "旭川|AKJ", "函館|HKD", "秋田|AXT", "福島|FKS", "仙台|SDJ", "茨城|IBR", "東京成田|NRT", "東京羽田|HND", "新潟|KIJ", "名古屋|NGO", "大阪|KIX", "岡山|OKJ", "高知|KCZ", "福岡|FUK", "佐賀|HSG", "大分|OIT", "宮崎|KMI", "沖繩|OKA", "-----", "首爾(仁川)|ICN", "釜山|PUS", "濟州|CJU"],
          // ],
        },
        // {
        //   country: "台中|RMQ",
        //   city: ["東京成田|NRT", "名古屋|NGO", "-----", "首爾(仁川)|ICN", "釜山|PUS", "-----", "澳門|MFM", "-----", "富國島|PQC"],
        // },
        {
          country: "高雄|KHH",
          city: [
            "札幌|CTS",
            "東京成田|NRT",
            "名古屋|NGO",
            "大阪|KIX",
            "岡山|OKJ",
            "福岡|FUK",
            "沖繩|OKA",
            "-----",
            // "首爾(仁川)|ICN",
            "首爾(金浦)|GMP",
            // "釜山|PUS",
            // "-----",
            // "澳門|MFM",
            // "-----",
            // "曼谷(廊曼)|DMK",
            // "-----",
            // "富國島|PQC",
            // "沖繩|OKA"
          ],
        },
        {
          country: "-----",
          city: [],
        },
        {
          country: "札幌|CTS",
          city: ["台北(桃園)|TPE", "高雄|KHH"],
        },
        {
          country: "旭川|AKJ",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "函館|HKD",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "旭川|AKJ",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "秋田|AXT",
          city: ["台北(桃園)|TPE"],
        },
        // {
        //   country: "花卷|HNA",
        //   city: ["台北(桃園)|TPE"],
        // },
        {
          country: "福島|FKS",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "仙台|SDJ",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "茨城|IBR",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "東京成田|NRT",
          city: ["台北(桃園)|TPE", "高雄|KHH"],
        },
        {
          country: "東京羽田|HND",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "新潟|KIJ",
          city: ["台北(桃園)|TPE"],
        },
        // {
        //   country: "小松|KMQ",
        //   city: ["台北(桃園)|TPE"],
        // },
        {
          country: "名古屋|NGO",
          city: ["台北(桃園)|TPE", "高雄|KHH"],
        },
        {
          country: "大阪|KIX",
          city: ["台北(桃園)|TPE", "高雄|KHH"],
        },
        {
          country: "岡山|OKJ",
          city: ["台北(桃園)|TPE", "高雄|KHH"],
        },
        // {
        //   country: "米子|YGJ",
        //   city: ["台北(桃園)|TPE"],
        // },
        {
          country: "高知|KCZ",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "福岡|FUK",
          city: ["台北(桃園)|TPE", "高雄|KHH"],
        },
        {
          country: "佐賀|HSG",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "大分|OIT",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "宮崎|KMI",
          city: ["台北(桃園)|TPE"],
        },
        {
          country: "沖繩|OKA",
          city: ["台北(桃園)|TPE", "高雄|KHH"],
        },
        {
          country: "-----",
          city: [],
        },
        {
          country: "首爾(仁川)|ICN",
          city: [
            "台北(桃園)|TPE",
            // "台中|RMQ",
            // "高雄|KHH",
            // "沖繩|OKA"
          ],
        },
        {
          country: "首爾(金浦)|GMP",
          city: [
            // "台北(桃園)|TPE",
            "高雄|KHH",
            // "沖繩|OKA"
          ],
        },
        {
          country: "釜山|PUS",
          city: [
            "台北(桃園)|TPE",
            // "台中|RMQ",
            // "高雄|KHH",
            // "沖繩|OKA"
          ],
        },
        // {
        //   country: "大邱|TAE",
        //   city: [
        //     "台北(桃園)|TPE",
        //     // "高雄|KHH",
        //     // "沖繩|OKA"
        //   ],
        // },
        {
          country: "濟州|CJU",
          city: [
            "台北(桃園)|TPE",
            // "高雄|KHH",
            // "沖繩|OKA"
          ],
        },
        // {
        //   country: "-----",
        //   city: [],
        // },
        // {
        //   country: "澳門|MFM",
        //   city: ["台北(桃園)|TPE", "台中|RMQ", "高雄|KHH"],
        // },
        // {
        //   country: "-----",
        //   city: [],
        // },
        // {
        //   country: "曼谷(廊曼)|DMK",
        //   city: ["高雄|KHH"],
        // },
        // {
        //   country: "-----",
        //   city: [],
        // },
        // {
        //   country: "富國島|PQC",
        //   city: ["台北(桃園)|TPE", "台中|RMQ", "高雄|KHH"],
        // },
        // {
        //   country: "-----",
        //   city: [],
        // },
        // {
        //   country: "普吉島|HKT",
        //   city: ["台北(桃園)|TPE"],
        // },
      ],
    }
  },
  methods: {
    getAirlineName(airline) {
      let airlineText = ""
      if (airline == "IT") {
        airlineText = "台灣虎航"
      }
      if (airline == "MM") {
        airlineText = "樂桃航空"
      }
      if (airline == "TR") {
        airlineText = "酷航"
      }
      if (airline == "7C") {
        airlineText = "濟州航空"
      }
      if (airline == "BX") {
        airlineText = "釜山航空"
      }
      if (airline == "TW") {
        airlineText = "德威航空"
      }
      if (airline == "LJ") {
        airlineText = "真航空"
      }
      if (airline == "SL") {
        airlineText = "泰國獅子航空"
      }
      if (airline == "VZ") {
        airlineText = "泰國越捷航空"
      }
      if (airline == "OD") {
        airlineText = "馬來西亞峇迪航空"
      }
      if (airline == "ZV") {
        airlineText = "威航"
      }
      if (airline == "GK") {
        airlineText = "捷星日本"
      }
      if (airline == "3K") {
        airlineText = "捷星亞洲"
      }
      if (airline == "JW") {
        airlineText = "香草航空"
      }
      if (airline == "TZ") {
        airlineText = "酷航"
      }
      if (airline == "FD") {
        airlineText = "泰國亞洲航空"
      }
      if (airline == "D7") {
        airlineText = "全亞洲航空"
      }
      if (airline == "VJ") {
        airlineText = "越捷航空"
      }
      return airlineText
    },
    getAirportText(iata_code) {
      let airportText = null
      switch (iata_code) {
        case "TPE":
          airportText = "台北(桃園) TPE"
          break
        case "KHH":
          airportText = "高雄 KHH"
          break
        case "RMQ":
          airportText = "台中(清泉崗) RMQ"
          break
        case "IBR":
          airportText = "茨城 IBR"
          break
        case "NRT":
          airportText = "東京(成田) NRT"
          break
        case "HND":
          airportText = "東京(羽田) HND"
          break
        case "CTS":
          airportText = "札幌(新千歲) CTS"
          break
        case "AKJ":
          airportText = "旭川 AKJ"
          break
        case "HKD":
          airportText = "函館 HKD"
          break
        case "HNA":
          airportText = "花卷 HNA"
          break
        case "SDJ":
          airportText = "仙台 SDJ"
          break
        case "AXT":
          airportText = "秋田 AXT"
          break
        case "KIJ":
          airportText = "新潟 KIJ"
          break
        case "KMQ":
          airportText = "小松 KMQ"
          break
        case "NGO":
          airportText = "名古屋(中部) NGO"
          break
        case "KIX":
          airportText = "大阪(關西) KIX"
          break
        case "KCZ":
          airportText = "高知 KCZ"
          break
        case "OKJ":
          airportText = "岡山 OKJ"
          break
        case "FUK":
          airportText = "福岡 FUK"
          break
        case "HSG":
          airportText = "佐賀 HSG"
          break
        case "OKA":
          airportText = "沖繩(那霸) OKA"
          break
        case "FKS":
          airportText = "福島 FKS"
          break
        case "OIT":
          airportText = "大分 OIT"
          break
        case "YGJ":
          airportText = "鳥取(米子) YGJ"
          break
        case "KMI":
          airportText = "宮崎 KMI"
          break

        case "ICN":
          airportText = "首爾(仁川) ICN"
          break
        case "GMP":
          airportText = "首爾(金浦) GMP"
          break
        case "PUS":
          airportText = "釜山 PUS"
          break
        case "TAE":
          airportText = "大邱 TAE"
          break
        case "CJU":
          airportText = "濟州 CJU"
          break
        case "MFM":
          airportText = "澳門 MFM"
          break
        case "PQC":
          airportText = "富國島 PQC"
          break
        case "DMK":
          airportText = "曼谷(廊曼) DMK"
          break

        default:
          airportText = "未定義"
          break
      }

      return airportText
    },
  },
}
