<template>
  <div class="col">
    <!-- <div class="alert alert-warning" role="alert">
      <p>溫馨提醒：如透過行動裝置進行操作，由於本站對於in-app瀏覽器（如Facebook 或 LINE 內建）支援程度不佳，如遇到資料無法呈現，可建議使用 Google Chrome、Safari 等系統原生或第三方瀏覽器，此外本站因有使用到統計圖表進行資料呈現，建議可將裝置採橫向方式進行操作以便取得較佳的查詢效果</p>
    </div> -->
    <div class="container-fluid mb-3 bg-light rounded" v-if="hot_visible == 1">
      <div class="table-wrap table-responsive">
        <table class="table table-borderless table-striped">
          <thead>
            <tr class="p-0">
              <td class="text-center w100 p-0 py-2 align-middle" scope="col" colspan="6">
                <b class="text-muted">
                  <h3>
                    <p class="text-primary">{{ hot_maintitle }}</p>
                  </h3>
                  <small>{{ hot_subtitle }}</small></b
                >
              </td>
            </tr>
            <tr>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                <b class="text-muted">旅行日期</b>
              </td>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                <b class="text-muted">出發地</b>
              </td>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                <b class="text-muted">目的地</b>
              </td>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                <b class="text-muted">班次</b>
              </td>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                <b class="text-muted">價格</b>
              </td>
              <!-- <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                <b class="text-muted">價格2</b>
              </td> -->
              <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                <b class="text-muted">資料時間</b>
              </td>
            </tr>
          </thead>
          <tbody class="table-group-divider" v-if="rows.data.length">
            <tr class="border-bottom bg-white" v-for="(item, key) in rows.data" :key="key">
              <td class="text-center align-middle fs-5">
                {{ item.departureDate }} <small>({{ item.departureDate | getWeekDay }})</small>
              </td>
              <td class="text-center align-middle fs-5">{{ item.departureStation }}</td>
              <td class="text-center align-middle fs-5">{{ item.arrivalStation }}</td>
              <td class="text-center align-middle fs-5">{{ item.flightNo }}</td>
              <td class="text-center align-middle fs-5">{{ item.fare1Amount.toLocaleString() }}</td>
              <!-- <td class="text-center align-middle" v-if="item.fare2Amount == '2299'">
                <span class="text-danger">{{ item.fare2Amount.toLocaleString() }}</span>
              </td>
              <td class="text-center align-middle" v-else>{{ item.fare2Amount.toLocaleString() }}</td> -->
              <td class="text-center align-middle fs-5">{{ item.fetchCreatedAt }}</td>
              <!-- <td class="text-center align-middle fs-5">{{ item.createdAt }}</td> -->
            </tr>
          </tbody>
          <tbody class="table-group-divider" v-else>
            <tr class="border-bottom bg-white">
              <td class="text-center align-middle" colspan="6">目前尚無資料</td>
            </tr>
          </tbody>
          <!-- <tfoot>
            <tr>
              <td class="text-start align-middle" colspan="5">
                <small>*僅供參考，上述資料均是建立在搜尋人數為１的情況，由於有時效性問題，建議要留意一下資料取得時間</small>
              </td>
              <td class="text-end align-middle" colspan="1"><img src="@/assets/jusbklogo.png" width="99" alt="jusbklogo" /></td>
            </tr>
          </tfoot> -->
        </table>
      </div>
    </div>
    <hr />
    <div class="container-fluid mb-3 bg-light rounded overflow-scroll shadow p-3 mb-5 bg-body rounded g-light bg-gradient" v-if="trend_visible == 1">
      <div class="table-wrap table-responsive">
        <table class="table table-borderless table-striped">
          <thead>
            <tr class="p-0">
              <td class="text-center w100 p-0 py-2 align-middle" scope="col" colspan="7">
                <b class="text-muted">
                  <h3>
                    <p class="text-primary">{{ trend_maintitle }}</p>
                  </h3>
                  <small>{{ trend_subtitle }}</small></b
                >
              </td>
            </tr>
            <tr>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                <b class="text-muted">旅行日期</b>
              </td>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                <b class="text-muted">出發地</b>
              </td>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                <b class="text-muted">目的地</b>
              </td>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                <b class="text-muted">班次</b>
              </td>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                <b class="text-muted">價格/漲降</b>
              </td>
              <!-- <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                <b class="text-muted">剩餘率</b>
              </td> -->
              <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                <b class="text-muted">資料時間 (UTC+8)</b>
              </td>
              <td class="text-center w100 p-0 py-2 align-middle" scope="col">
                <b class="text-muted">#前往官網</b>
              </td>
            </tr>
          </thead>
          <tbody class="table-group-divider" v-if="rows2.data.length">
            <tr class="border-bottom bg-white" v-for="(item, key) in rows2.data" :key="key">
              <td class="text-center align-middle fs-6">
                {{ item.departureDate }} <small>({{ item.departureDate | getWeekDay }})</small>
              </td>
              <td class="text-center align-middle fs-6">{{ item.departureStation }}</td>
              <td class="text-center align-middle fs-6">{{ item.arrivalStation }}</td>
              <!-- <td class="text-center align-middle table-warning" v-else-if="item.flightNo == 'IT610'">
                <span class="text-primary">{{ item.flightNo }}</span>
              </td> -->
              <td class="text-center align-middle fs-6">{{ item.flightNo }}</td>
              <!-- <td class="text-center align-middle fs-6" v-if="item.fare1Amount == '2299'">
                <span class="text-danger">{{ item.fare1Amount.toLocaleString() }}</span>
              </td> -->
              <td class="text-center align-middle fs-6">
                <span v-if="item.flightNo.substring(0, 3) === 'IT6'">{{ (item.fare1Amount - 950).toLocaleString() }}</span>
                <span v-else>{{ item.fare1Amount.toLocaleString() }}</span>

                (<span v-if="item.fare_trend_diff1 < 0"
                  ><B><i class="bi bi-arrow-down-short" style="font-size: 1.4rem; color: #22c32e"></i></B>{{ item.fare_trend_diff1.toLocaleString() }})</span
                >
                <span v-else
                  ><B><i class="bi bi-arrow-up-short" style="font-size: 1.4rem; color: #ff0000"></i></B>{{ item.fare_trend_diff1.toLocaleString() }})</span
                >

                <!-- {{ item.soldRate }} -->
                <!-- <B><i class="bi bi-arrow-down-short" style="font-size: 1.4rem; color: #22c32e"></i></B>{{ item.fare_trend_diff2.toLocaleString() }} -->
              </td>
              <!-- <td class="text-center align-middle"> -->
              <!-- <span class="text-danger">{{ item.fare2Amount.toLocaleString() }}</span> -->
              <!-- {{ item.soldRate }} -->
              <!-- </td> -->
              <!-- <td class="text-center align-middle" v-else>{{ item.fare2Amount.toLocaleString() }}</td> -->
              <td class="text-center align-middle fs-6">{{ item.createdAt }}</td>
              <td class="text-center align-middle fs-6">
                <!-- # -->
                <!-- <span v-html="showConfirm(item.airline, item.departureDate, item.departureStation, item.arrivalStation)"></span> -->
                <span
                  ><button type="button" class="btn btn-link" @click="showConfirm(item.airline, item.departureDate, item.departureStation, item.arrivalStation)"><i class="bi bi-box-arrow-up-right"></i> 前往官網</button></span
                >
              </td>
            </tr>
          </tbody>
          <tbody class="table-group-divider" v-else>
            <tr class="border-bottom bg-white">
              <td class="text-center align-middle" colspan="7">目前尚無資料</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td class="text-start align-middle" colspan="6">
                <small>*僅供參考，上述資料均是建立在搜尋人數為１的情況，由於有時效性問題，建議要留意一下資料取得時間</small>
              </td>
              <td class="text-end align-middle" colspan="1"><img src="@/assets/jusbklogo.png" width="99" alt="jusbklogo" /></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <!-- <BootstrapTable ref="table" :columns="columns" :data="data" :options="options" /> -->
  </div>
</template>
<!-- <style>
  @import url("https://unpkg.com/bootstrap-table@1.21.4/dist/bootstrap-table.min.css");
</style> -->
<script>
  import axios from "axios"
  import moment from "moment"

  // import "bootstrap-table/dist/bootstrap-table.js"
  // import BootstrapTable from "bootstrap-table/dist/bootstrap-table-vue.esm.js"

  export default {
    name: "VueLazyBag",
    mounted() {
      this.initLazyBag()
    },
    data: function () {
      return {
        rows: [],
        rows2: [],
        hot_maintitle: "",
        hot_subtitle: "",
        hot_visible: 0,

        trend_maintitle: "",
        trend_subtitle: "",
        trend_visible: 0,
      }
    },
    methods: {
      showConfirm(airline, departureDate, departureStation, arrivalStation) {
        this.$confirm("由於資料具有時效性關係，實際價格以各航空公司官方訂位系統上所查詢為準").then(async () => {
          let newDepartureDate = moment(departureDate).format("YYYYMMDD")
          const url = `${this.$webUrl}go?ref=${airline}-${departureStation}${arrivalStation}-${newDepartureDate}`
          // const url = this.showlink(airline, departureDate, departureStation, arrivalStation, affiliateChannel, promoCode, "text")
          window.open(url, "_blank")
        })
      },
      initLazyBag() {
        const me = this
        axios.get(`${this.$apiUrl}lazybag`).then((response) => {
          me.rows = response.data.hot
          me.hot_maintitle = response.data.hot.maintitle
          me.hot_subtitle = response.data.hot.subtitle
          me.hot_visible = response.data.hot.visible

          me.rows2 = response.data.trend
          me.trend_maintitle = response.data.trend.maintitle
          me.trend_subtitle = response.data.trend.subtitle
          me.trend_visible = response.data.trend.visible
        })
      },
    },
    filters: {
      getWeekDay: function (date) {
        const weeks = ["", "一", "二", "三", "四", "五", "六", "日"]
        return weeks[moment(date).isoWeekday()]
      },
      formatDate: function (date) {
        return moment(date).format("MM/DD")
      },
    },
  }
</script>

<style></style>
